import { Navigate, useNavigate } from "react-router-dom";
import React, { useEffect } from 'react'
import { AppBar, Avatar, Badge, Box, Button, ButtonGroup, Card, Fab, Grid, IconButton, ListItemIcon, Menu, MenuItem, Modal, Paper, Toolbar, Typography } from "@mui/material";
import { Add, Check, Home, Logout, Notifications, Person, PlayArrow, Settings, Store } from "@mui/icons-material";
import logo from '../../images/logo192.png'
import { useDispatch } from "react-redux";
import { setCurrentUser } from "../../features/auth/currentUserSlice";
import { useSendLogoutMutation } from "../../features/auth/authApiSlice";
import { useGetCurrUserQuery } from "../../features/auth/currentUserApiSlice";
import { useGetPlayableGameMutation } from "../../features/games/gamesApiSlice";
import { removeActiveGame, setActiveGame } from "../../features/games/gameSlice";
import Play from "../../features/games/PlayViews/Play";
import NewGame from "../../features/games/NewGame";
import AvatarMenu from "./AvatarMenu";


const BannerLoggedIn = () => {
    const [rulesOpen, setRulesOpen] = React.useState(false);
    const handleRulesOpen = () => setRulesOpen(true);
    const handleRulesClose = () => setRulesOpen(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [playModalOpen, setPlayModalOpen] = React.useState(false);
    const [newGameModalOpen, setNewGameModalOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    //const { pathname } = useLocation();
    const onGoHomeClicked = () => navigate('/home');

    const [getPlayableGame, {
        data: game,
        isLoading: isGameLoading,
        isError: isGameError,
        error: gameError,
        isSuccess: isGameSuccess
    }] = useGetPlayableGameMutation();

    const {
        data: user,
        isLoading: isUserLoading,
        isSuccess: isUserSuccess,
        isError: isUserError,
        error: userError
    } = useGetCurrUserQuery();

    const [sendLogout, {
        isLoading: isLogoutLoading,
        isSuccess: isLogoutSuccess,
        isError: isLogoutError,
        error: logoutError
    }] = useSendLogoutMutation();

    useEffect(() => {
        if (isGameSuccess) {
            setErrorMessage('');
            dispatch(setActiveGame({ game }));
        }
    }, [isGameSuccess]);

    useEffect(() => {
        if (isGameError) {
            setErrorMessage(gameError?.data?.message);
            setActiveGame(null)
        }
    }, [isGameError]);

    useEffect(() => {
        if (isGameLoading) {
            setErrorMessage("Loading...");
        }
    }, [isGameLoading]);

    useEffect(() => {
        if (isUserError) {
            console.log(user.error);
        }
    }, [isUserError]);

    useEffect(() => {
        if (isUserSuccess) {
            dispatch(setCurrentUser({ user }));
        }
    }, [isUserSuccess]);

    const onNewGameSubmit = async (e, gameId) => {
        newGameModalClose();
        onPlayClicked(e, gameId);
    }
    
    const onPlayClicked = async (e, gameId) => {
        try {
            //console.log(`Getting game: ${gameId}`);
            await getPlayableGame(gameId ?? null);
            setPlayModalOpen(true);
        } catch (error) {
            console.log(error);
        }
    };

    const newGameModalClose = async () => {
        setNewGameModalOpen(false);
    }

    const playModalClose = () => {
        setPlayModalOpen(false);
    }

    let usernameDisplay = '';
    let userAvatar = '';
    if (isUserLoading) usernameDisplay = 'Loading';
    if (isUserSuccess) {
        usernameDisplay = user.username;
        userAvatar = user.profile.avatar;
    }

    const content = (
        <>
            <header>
                <AppBar position="static">
                    <Toolbar>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 5 }}>
                            <Button onClick={onGoHomeClicked} title="home" disableRipple disableElevation sx={{ "&:hover": { backgroundColor: 'transparent' } }}>
                                <img src={logo} style={{ width: 'auto', height: 64 }} aria-label="ArtRush" />
                            </Button>
                        </Box>
                        <Button sx={{ mr: 2 }}>
                            <Typography variant="h6" component="div" onClick={() => { navigate('/games') }} fontFamily={'Acme'}>
                                Finished Games
                            </Typography>
                        </Button>
                        <Button sx={{ mr: 2 }}>
                            <Typography variant="h6" component="div" onClick={handleRulesOpen} fontFamily={'Acme'}>
                                Rules
                            </Typography>
                        </Button>
                        <Button sx={{ mr: 2 }} href="https://discord.gg/N7faCrMzmf" target="_new" rel="noreferrer noopener">
                            <Typography variant="h6" component="div" fontFamily={'Acme'}>
                                Join the Discord
                            </Typography>
                        </Button>
                        <Button sx={{ mr: 2 }} variant="contained" color='secondary'>
                            <Store sx={{ mr: 1 }} />
                            <Typography variant="h6" component="div" onClick={() => { navigate('/shop') }} fontFamily={'Acme'}>
                                Shop
                            </Typography>
                        </Button>
                        <Box sx={{ flexGrow: 1 }} />
                        <ButtonGroup sx={{ mr: 2 }}>
                            <Button variant="contained" color="success" onClick={onPlayClicked}>
                                <PlayArrow style={{ color: '#fff' }} size="large" />
                                <Typography variant="h6" color="White" fontWeight="700" fontFamily={'Acme'}>
                                    Play!
                                </Typography>
                            </Button>
                            <Button variant="contained" color="primary" sx={{ paddingX: 1 }} onClick={() => setNewGameModalOpen(true)}>
                                <Add style={{ color: '#fff' }} size='large' />
                            </Button>
                        </ButtonGroup>

                        <AvatarMenu />
                    </Toolbar>
                </AppBar>
            </header>

            <Modal open={rulesOpen} /*onClose={handleRulesClose}*/>
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 512,
                }}>
                    <Box sx={{ mx: 5, my: 2 }}>
                        <Typography variant="h4" sx={{ flexGrow: 1 }} fontFamily={'Lora'} fontWeight="700" align="center">Rules</Typography>
                        <Box sx={{ mt: 2, mb: 5 }}>
                            <Card raised sx={{ my: 2 }}>
                                <Typography sx={{ m: 2 }} fontFamily={'Acme'} fontWeight="100" align="center">Public games should be considered PG-13 so do not draw or type anything too graphic. Hateful or threatening content is never allowed, even in private games</Typography>
                            </Card>
                            <Card raised sx={{ my: 2 }}>
                                <Typography sx={{ m: 2 }} fontFamily={'Acme'} fontWeight="100" align="center">Do not inject any characters or franchises into a game that were not already there and do your best to describe or draw the content in the previous panel. <br /><strong>Please do not derail any games!</strong></Typography>
                            </Card>
                            <Card raised sx={{ my: 2 }}>
                                <Typography sx={{ m: 2 }} fontFamily={'Acme'} fontWeight="100" align="center">Witch hunting and brigading will not be tolerated. If you encounter a problematic user please report them to the moderation team</Typography>
                            </Card>

                            <Card raised sx={{ my: 2 }}>
                                <Typography sx={{ m: 2 }} fontFamily={'Acme'} fontWeight="100" align="center">Not every game mode is a competition and there's no need to be an artist to have fun!</Typography>
                            </Card>

                        </Box>
                        <Fab variant="extended" sx={{ margin: '0 auto', display: "flex" }} onClick={handleRulesClose} color='info'>

                            <Typography fontFamily={'Acme'} variant='h6' color={'white'}>Accept</Typography>
                        </Fab>
                    </Box>
                </Paper>
            </Modal>

            <Modal open={playModalOpen}>
                {
                    isGameError || isGameLoading ?
                        <>
                            <Paper sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}>
                                <Grid container direction={'column'}>
                                    <Typography fontFamily={'Acme'} variant="h4" sx={{ m: 5 }} align="center">{errorMessage}</Typography>
                                    <Button onClick={playModalClose} fontFamily={'Acme'}>Close</Button>
                                </Grid>
                            </Paper>
                        </> :
                        <>
                            <Play exitGame={playModalClose} newGame={onPlayClicked} />
                        </>
                }
            </Modal>
            <Modal open={newGameModalOpen} onClose={newGameModalClose}>
                <Paper sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                }}>
                    <NewGame onSubmit={onNewGameSubmit} />
                </Paper>
            </Modal>
        </>
    );
    return content;
}

export default BannerLoggedIn