import { Link, useNavigate } from "react-router-dom";
import logo from '../images/logo.png'
import React from 'react'
import { Button, Card, Container, Grid, Tooltip, Typography } from "@mui/material";
import { Diamond } from "@mui/icons-material";

const Public = () => {
    const navigate = useNavigate();
    const Content = (
        <Container>
            <Grid container direction={'row'} justifyContent={'center'}>
                <Typography sx={{mt:2}}variant="h3" fontFamily={'Acme'} align="center">Welcome to</Typography>
                <img src={logo} style={{width: 'auto', height: 96}} aria-label="ArtRush"/>
                <Typography sx={{mt:2}}variant="h3" fontFamily={'Acme'} align="center"><sup style={{fontSize:18}}>&copy;</sup></Typography>
            </Grid>
            <main>
                <Card raised sx={{padding:2, m:2}}>
                <Typography variant="h4" fontFamily={'Acme'} fontWeight="400" align="center" sx={{my:3}}>
                    ArtRush is a free website game where players can come together to play turn-based drawing games!
                </Typography>
                <p/>
                <Typography variant="h6" fontFamily={'Acme'} fontWeight="400" align="center">
                    As you play the game you'll eventually encounter something called a Crayon. 
                    Crayons are the in-game currency that lets you unlock even more fun features like color palettes and the ability to start your own games. 
                    You can even award Crayons to drawings and artists that you really like! 
                </Typography>
                <p/>
                <Typography variant="h6" fontFamily={'Acme'} fontWeight="400" align="center"> {/*TODO: change to 'are available' when store is active*/}
                    Crayons will be available for purchase on our store but you don't need to spend any money to earn them - 
                    just keep playing and you can earn them by completing daily tasks and by participating in competitions and popular games 😃
                </Typography>
                <Grid container direction="column" alignItems="center" justifyContent="center" sx={{my:4}}>
                    <Button variant="contained" color="success" onClick={() => navigate("/signup")}><Typography fontFamily={'Acme'} variant="h3" color="white">Register Now!</Typography></Button>
                    <Button color="primary" sx={{mt:1}} onClick={() => navigate("/Login")}><Typography variant="button" color="inherit" fontFamily={'Acme'} fontWeight="400">or Log In if you already have an account</Typography> </Button>
                </Grid>
                
                </Card>
            </main>
        </Container>
    );
  return Content;
}

export default Public