import { Container, Table, TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'
import User from './User';
import { useGetAllUsersQuery } from './usersApiSlice'

const UsersList = () => {
    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetAllUsersQuery('usersList');

    let content

    if (isLoading) content = <p>Loading...</p>
    if (isError) {
        content = <p>{error?.data?.message}</p>
    }

    if (isSuccess) {
        const { ids } = users;

        const tableContent = ids?.length
            ? ids.map(userId => <User key={userId} userId={userId} />)
            : null;

        content = (
            <Container>
                <Table aria-label="users">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left"></TableCell>
                            <TableCell align="left">Username</TableCell>
                            <TableCell align="left">Email</TableCell>
                            <TableCell align="left">Role</TableCell>
                            <TableCell align="left">Active</TableCell>
                            <TableCell align="left">Premium</TableCell>
                        </TableRow>
                        {tableContent}
                    </TableHead>
                </Table>
            </Container>

        )
    }
    return content;
}

export default UsersList 