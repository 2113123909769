import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../auth/currentUserSlice';
import { Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, Slider, Switch, Tooltip, Typography } from '@mui/material';
import { useGetAllPalettesQuery } from '../palette/paletteApiSlice';
import Palette from '../palette/Palette';
import { useNewGameMutation } from './gamesApiSlice';
import { Error, Help } from '@mui/icons-material';

const NewGame = (params) => {

  const {
    data: palettes,
    isLoading: isPalettesLoading,
    isSuccess: isPalettesSuccess,
    isError: isPaletteError,
    error: paletteError
  } = useGetAllPalettesQuery('palettesList');

  const [newGame, {
    data: gameId,
    isLoading: isGameLoading,
    isSuccess: isGameSuccess,
    isError: isGameError,
    error: gameError
  }] = useNewGameMutation();

  const [gameType, setGameType] = React.useState(1);
  const [privateGame, setPrivateGame] = React.useState(false);
  const [firstPanelType, setFirstPanelType] = React.useState(1);
  const [numPlayers, setNumPlayers] = React.useState(12);
  const [gameSpeed, setGameSpeed] = React.useState(2);
  const [paletteMode, setPaletteMode] = React.useState('');
  const [canSelectPanelType, setCanSelectPanelType] = React.useState(true);
  const [canSelectGameSpeed, setCanSelectGameSpeed] = React.useState(true);

  const gamePrice = () => {
    let cost = captionPrice;
    if(firstPanelType > 1) cost += 50;
    if(numPlayers > 12) cost += 50;
    if(numPlayers > 16) cost += 50;
    if(gameSpeed > 2) cost += 50;
    return cost
  }

  const onGameTypeChange = (e) => {
    setGameType(e.target.value);
  }
  const onPrivateGameChanged = (e) => {
    setPrivateGame(e.target.value);
  }
  const onFirstPanelTypeChange = (e) => {
    setFirstPanelType(e.target.value);
  }
  const onNumPlayersChange = (e) => {
    setNumPlayers(e.target.value);
  }
  const onGameSpeedChange = (e) => {
    setGameSpeed(e.target.value);
  }
  const onPaletteChange = (e) => {
    setPaletteMode(e.target.value);
  }


  const onPlayClicked = async () => {
    let gameLength, gameMode, palette, nextPanel, timeLimit;
    switch (gameType) {
      default:
      case 1:
        gameMode = "Miscommunication";
        break;
      case 2:
        gameMode = "Story";
        break;
      case 3:
        gameMode = "Imitation";
        break;
      case 4:
        gameMode = "Completion";
        break;
      case 5:
        gameMode = "Animation";
        break;
      case 6:
        gameMode = "Competition";
        break;
      case 7:
        gameMode = "Compecaption";
        break;
    }
    switch (firstPanelType) {
      default:
      case 1:
        nextPanel = 'caption';
        break;
      case 2:
        nextPanel = 'drawing';
    }
    switch (gameSpeed) {
      case 1:
        timeLimit = 30 * 1000;
        break;
      default:
      case 2:
        timeLimit = 5 * 60 * 1000;
        break;
      case 3:
        timeLimit = 15 * 60 * 1000;
        break;
    }
    gameLength = numPlayers;
    palette = paletteMode;
    const gameParams = {
      gameLength,
      gameMode,
      palette,
      nextPanel,
      timeLimit,
      privateGame
    }
    await newGame({gameParams});
  }

  useEffect(() => {
    switch (gameType) {
      case 4:
        setCanSelectGameSpeed(false);
        setGameSpeed(1);
      case 3:
      case 5:
      case 7:
        setCanSelectPanelType(false);
        setFirstPanelType(2);
        break;
      case 6:
        setCanSelectPanelType(false);
        setFirstPanelType(1);
        break;
      case 1:
      case 2:
      default:
        setCanSelectPanelType(true);
        setCanSelectGameSpeed(true);
        break;
    }
  }, [gameType]);
  useEffect(() => {
    if (user?.purchasedPalettes?.length) setPaletteMode(user.purchasedPalettes[0])
  }, [isPalettesSuccess])
useEffect(() => {
  if(isGameSuccess) params.onSubmit(null,gameId.id);
},[isGameSuccess])

  const panelTypeMarks = [{ value: 1, label: "Caption" }, { value: 2, label: "Drawing" }];
  const playerCountMarks = [{ value: 8, label: '8' }, { value: 12, label: '12' }, { value: 16, label: '16' }, { value: 24, label: '24' }];
  const speedMarks = [{ value: 3, label: "Detail" }, { value: 2, label: "Normal" }, { value: 1, label: "Frenzy" },];
  //TODO: Dynamically pull game pricing
  const captionPrice = 50;
 

  const user = useSelector(selectCurrentUser);

  let content;
  if (isPalettesLoading) content = <Typography variant='h3' fontFamily={'Acme'}>Loading Palettes...</Typography>
  if (isPaletteError) content = <Typography variant='h4' fontFamily={'Acme'}>{paletteError?.data?.message}</Typography>
  if (isPalettesSuccess) {
    const { ids } = palettes;
    const userPalettes = user?.purchasedPalettes?.length ? user.purchasedPalettes.map(paletteId =>
      <MenuItem key={paletteId} value={paletteId}>
        <Palette paletteId={paletteId} />
      </MenuItem>) : null;

    content =
      <Grid container direction='column' sx={{ p: 5, minWidth: 512 }}>
        <Typography variant='h4' fontFamily={'Acme'} fontWeight={700} align='center' sx={{ mb: 3 }}>Start a New Game</Typography>
        <Grid item>
          <Grid container direction={'row'} justifyContent={'space-between'} sx={{ mb: 2 }}>
          <Grid item flex={1} sx={{mr:5}}>
            <FormControl fullWidth>
              <FormLabel id='gameType-label' sx={{ flex: 1 }}>Game Type</FormLabel>
              <Select value={gameType} id='gameType' onChange={onGameTypeChange}>
                <MenuItem value={1}>Miscommunication</MenuItem>
                <MenuItem value={2} disabled>Story</MenuItem>
                <MenuItem value={3} disabled>Imitation</MenuItem>
                <MenuItem value={4} disabled>Completion</MenuItem>
                <MenuItem value={5} disabled>Animation</MenuItem>
                <MenuItem value={6} >Competition</MenuItem>
                <MenuItem value={7} >Caption Contest</MenuItem>
              </Select>
            </FormControl>
            </Grid>
            <Grid item>
            <FormControl>
            <Tooltip title="This game will only be playable by people with the link and will not show up in the public feed">
              <FormLabel id='private-label'>
                Private
              </FormLabel>
              </Tooltip>
              <Checkbox disableRipple value={privateGame} onChange={onPrivateGameChanged}/>
            </FormControl>
            </Grid>
            
          </Grid>
        </Grid>
        <FormControl sx={{ mb: 2 }}>
          <FormLabel id='palette-label'>Color Palette</FormLabel>
          <Select value={paletteMode} onChange={onPaletteChange}>
            {userPalettes}
          </Select>
        </FormControl>
        <FormControl sx={{ mb: 2 }} disabled={!canSelectPanelType}>
          <FormLabel id='drawFirst-label'>First Panel Type</FormLabel>
          <Slider
            marks={panelTypeMarks}
            min={1}
            max={2}
            step={null}
            value={firstPanelType}
            onChange={onFirstPanelTypeChange}
            disabled={!canSelectPanelType}
            track={false}
          />
        </FormControl>
        <FormControl sx={{ mb: 2 }}>
          <FormLabel id='numPlayers-label'>Number of Rounds</FormLabel>
          <Slider
            marks={playerCountMarks}
            value={numPlayers}
            onChange={onNumPlayersChange}
            step={null}
            min={8}
            max={24}
          />
        </FormControl>
        <FormControl sx={{ mb: 4 }}>
          <FormLabel id='speed-label'>Game Speed</FormLabel>
          <Slider
            marks={speedMarks}
            step={null}
            min={1}
            max={3}
            value={gameSpeed}
            onChange={onGameSpeedChange}
            track={false}
            valueLabelDisplay='auto'
            valueLabelFormat={(v) => { return (v === 1 ? "30s" : v === 2 ? "5m" : "15m") }}
          />
        </FormControl>
        <Grid container direction={'column'} alignContent={'center'} alignItems={'center'} justifyItems={'center'} justifyContent={'center'}>
          <Grid item container direction={'row'} alignContent={'center'} alignItems={'center'} justifyItems={'center'} justifyContent={'center'} sx={{mb:2}}>
            {user.tokens < gamePrice() ? 
            <Tooltip title='You do not have enough crayons to play this game configuration!'>
              <Error color='error' fontSize='medium'/>
            </Tooltip>  : <></>}
          <Grid item>
            <Typography variant='h6' color={'orange'} fontFamily={'Acme'} align='center'>Price: {gamePrice()}</Typography>
          </Grid>
            
          </Grid>
          
          
        <Button variant='contained' color='success' onClick={onPlayClicked} disabled={user.tokens < gamePrice()}>
          <Typography variant="h3" color="White" fontWeight="700" fontFamily={'Acme'}>
            Play!
          </Typography>
        </Button>
        
        </Grid>

        <Typography display={isGameError || isPaletteError}>{gameError}</Typography>
      </Grid>
  }


  if (!(user.premium || user.role === 'moderator' || user.role === 'admin') && user.tokens < captionPrice) {
    content =
    <Grid container direction={'column'} sx={{m:3}}>
      <Typography variant='h3' fontFamily={'Acme'}>Oh no!</Typography>
      <Typography variant='h5' fontFamily={'Acme'} sx={{mb:2}}>You don't have enough crayons to start a game 😳</Typography>
      <Typography variant='h6' fontFamily={'Acme'}>Play some games to earn Crayons or go the the <a href='/Store'>Shop</a> to buy some more!</Typography>
    </Grid>
  }
  return content;
}

export default NewGame