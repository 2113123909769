import React from 'react'
import { useParams } from 'react-router-dom'

const GameView = () => {
  let { gameId } = useParams();
  return (
    <h3>Game ID: {gameId}</h3>
  )
}

export default GameView