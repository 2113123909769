import { Badge, Box, Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import React, { useEffect } from 'react'
import DrawView from './DrawView';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveGame, setActiveGame } from '../gameSlice';
import { useGetPlayableGameMutation, useSkipGameMutation, useSubmitGameMutation } from '../gamesApiSlice';
import { selectPaletteById, useGetPaletteQuery } from '../../palette/paletteApiSlice';
import { useNavigate } from 'react-router-dom';
import { Cancel } from '@mui/icons-material';
import CaptionView from './CaptionView';

const Play = (props) => {
    const activeGame = useSelector(selectActiveGame);
    //console.log(activeGame);
    const [game, setGame] = React.useState(activeGame);
    //console.log(game);
    const navigate = useNavigate();

    const onSubmitGame = async (content, panelType) => {
        await submitGame({content:content, id:game._id, panelType});
    }

    const onSkipGame = async () => {
        await skipGame({id:game._id})
    }

    let content = "Play";
        const {
            data: palette,
            isLoading,
            isSuccess,
            isError,
            error
        } = useGetPaletteQuery(game?.palette);
        const [submitGame, {
            isLoading: isGameSubmitLoading,
            isSuccess: isGameSubmitSuccess,
            isError: isGameSubmitError,
            error: gameError
        }] = useSubmitGameMutation();
        const [skipGame, {
            isLoading: isSkipLoading,
            isSuccess: isSkipSuccess,
            isError: isSkipError,
            error:  skipError
        }] = useSkipGameMutation();
        useEffect(() => {
            const newgame = async () => {
                await props.newGame();
            }
            if(isGameSubmitSuccess || isSkipSuccess) newgame();
        },[isGameSubmitSuccess, isSkipSuccess]);
        
        let firstPanel;
    
        if(isLoading)
        content = <Card raised><Typography>Loading Palette...</Typography></Card>
        if(isError)
        content = <Card raised><Typography>{error?.data?.message}</Typography></Card>
    
        if(isSuccess){
            if(!game) return <Card raised><Typography>Game was improperly loaded</Typography></Card>
            if (game.panel) {
                switch (game.panel.panelType) {
                    case "drawing":
                        firstPanel = <img src={`http://localhost:9000/images/${game.panel.content}.png`} alt='Previous Drawing' />
                        break;
                    case "caption":
                        firstPanel =
                            <Typography align='center' fontFamily={'Acme'} variant="h4" sx={{ wordBreak: "break-word", m: 1, alignSelf:'center' }}>{game.panel.content}</Typography>
                        break;
                }
            } else firstPanel = <Typography align='center' fontFamily={'Acme'} variant="h4" sx={{ wordBreak: "break-word", m: 1, alignSelf:'center' }}>New Game</Typography>

            const playPanel = game.nextPanel === 'caption' ?   <CaptionView submitCanvas={onSubmitGame} skipGame={onSkipGame}/> : <DrawView activePalette={palette} submitCanvas={onSubmitGame} skipGame={onSkipGame}/>
  
            content = (
                <Grid container direction={'row'} justifyItems={'center'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                    
                <Grid container item direction={'row'} justifyItems={'center'} justifyContent={'center'} alignContent={'center'} alignItems={'center'}>
                    <Grid item visibility={!(game?.panel)} >
                        <Box display='flex' margin='auto' justifyContent='center' alignContent='center'>
                            <Card sx={{ width: 256, height: 256}} raised>
                                <CardContent sx={{display: 'flex', flexDirection:'column', alignItems:'center', justifyContent:'center', alignItems:'center'}}>
                                    {firstPanel}
                                </CardContent>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item>
                       {playPanel}
                    </Grid>
                </Grid>
                </Grid>
            )
        }
    
    

    return content
}

export default Play