import { Typography } from '@mui/material'
import React from 'react'

const NotFound = () => {
    const quips = [
        "Pages? Where we're going we won't need pages!",
        "Pages? PAGES?! We don't need no stinkin PAGES!",
        "I can't believe you've done this.",
        "These are not the pages you are looking for",
        "I'm sorry, Dave. I'm afraid I can't do that",
        "You shall not pass!",
        "Just keep swimming",
        "Ow! ArtRush bit my finger!",
        "Wilsooooooon!",
        "Nobody here but us potatoes",
        "404 in that font looks like a crying wombat. Good job making him cry.",
        "You have hit a dead end. There is a road to the NORTH and a path to the WEST. There is a mysterious PAGE just out of reach.",
        "Ha, and you thought you were gonna find something interesting here",
        "404 just looks like lol with some italic upper case Ls getting in the way",
        "The page, Patrick. The page.",
        "No, that's not a caption. One of us just messed up",
        "4 8 15 16 23 42"
    ];
    const content = quips[Math.floor(Math.random()*quips.length)];
  return (
    <>
        <Typography sx={{m:5}} variant="h1" fontFamily={'Acme'} fontWeight='700' align="center">404</Typography>
        <p/>
        <Typography sx={{m:5}} variant="h4" fontFamily={'Acme'} align="center">{content}</Typography>
    </>
  )
}

export default NotFound