import React, { useEffect } from 'react'
import { useGetCurrUserProfileQuery } from '../auth/currentUserApiSlice'
import { Avatar, Badge, Button, Card, Grid, IconButton, TextField, Typography } from '@mui/material';
import { AddPhotoAlternate, AddPhotoAlternateOutlined } from '@mui/icons-material';

const Profile = () => {
  
  const errRef = React.useRef();
  const [oldPassword, setOldPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [emailField, setEmailField] = React.useState('');
  const [errMsg, setErrMsg] = React.useState('');

  const {data : user, isLoading: isUserLoading, isError: isUserError, isSuccess: isUserSuccess, error: userError} = useGetCurrUserProfileQuery();

  useEffect(() => {
    if(isUserSuccess){
      setEmailField(user.email);
    }
  },[isUserSuccess])
  

  let content;
  if (isUserLoading) content = <p>Loading...</p>
  if (isUserError) content = <p>{userError?.data?.message}</p>
  if (isUserSuccess) {
    const {birthday, email, premium, profile, tokens, username, verifiedEmail} = user;
    const avatarURL = `http://localhost:9000/avatars/${profile.avatar}.png`;
    content = 
    <Grid container direction='column' alignItems='center' justifyContent='center' sx={{m:2}}>
      <Typography variant="h4" fontFamily={'Acme'} align="center">{`${username}'s Profile`}</Typography>
        <Badge overlap='circular' anchorOrigin={{vertical:'bottom', horizontal:'right'}} badgeContent={<AddPhotoAlternateOutlined fontSize='large'/>}>
          <IconButton>
            <Avatar src={avatarURL} sx={{width:192,height:192}}/>
          </IconButton>
        </Badge>
      <Grid item>
        <Card raised sx={{m:2, py:2, px:3}}>
        <Typography variant="h6" fontFamily={'Acme'} align="center">{`Edit Profile`}</Typography>
          <Grid container direction='column' alignContent='center' justifyItems='center' rowGap={1.52}>
          <Typography color="error" sx={{my:1}} ref={errRef} aria-live="assertive">{errMsg}</Typography>
              <TextField
                label='Birthday' 
                disabled 
                defaultValue={new Date(birthday).toDateString()}/>
              <TextField 
                label='Email' 
                value={emailField} 
                onChange={e => setEmailField(e.target.value)} 
                autoComplete='off'/>
              <TextField 
                label='New Password'
                type='password'
                value={newPassword}
                onChange={e => setNewPassword(e.target.value)}
                inputProps={{ maxLength: 32 }}
                autoComplete={"new-password"}/>
              <TextField 
                label='Current Password' 
                required 
                type='password' 
                value={oldPassword} 
                onChange={e => setOldPassword(e.target.value)} 
                autoComplete='on'/>
              <Button type='outlined'>
                <Typography fontFamily={'Acme'} align='center'>Submit</Typography>
              </Button>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  }

  return content;
}

export default Profile