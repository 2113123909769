import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useRefreshMutation } from "./authApiSlice";
import usePersist from "../../hooks/usePersist";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";
import { Link } from "react-router-dom";

const PersistLogin = () => {

    const [persist] = usePersist();
    const token = useSelector(selectCurrentToken);
    const effectRan = useRef(false);

    const [trueSuccess, setTrueSuccess] = useState(false);

    const [refresh, {
        isUninitialized,
        isLoading,
        isSuccess,
        isError,
        error
    }] = useRefreshMutation();

    const location = useLocation();
    useEffect(() => {
        if(effectRan.current === true || process.env.NODE_ENV !== 'development'){
            const verifyRefreshToken = async () => {
                console.log('verifying refresh token');
                try{
                    const response = await refresh();
                    //const {accessToken} = response.data;
                    setTrueSuccess(true);
                }
                catch(err){
                    console.error(err);
                }
            }
            if (!token && persist) verifyRefreshToken();
        }
        return () => effectRan.current = true;

        // eslint-disable-next-line
    }, []);

    let content = <Outlet/>

    if(!persist) {
        console.log("no persist");
        content = <Outlet/>
    } else if(isLoading) {
        console.log("loading");
        content = <p>Loading...</p>;
    } else if(isError) {
        console.log("error");
        content = <Navigate to='/login' replace={true} state={{...location, redirected: true}}/>;
    } else if(isSuccess && trueSuccess) {
        console.log("success");
        content = <Outlet/>;
    } else if(token && isUninitialized) {
        console.log(`token and uninit = ${isUninitialized}`);
        content = <Outlet/>;
    }
        
    return content;

}
export default PersistLogin;