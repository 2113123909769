import {createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { setCredentials } from '../../features/auth/authSlice';

const baseQuery = fetchBaseQuery({
    baseUrl: 'http://localhost:3500/api/',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
        const token = getState().auth.token;
        if(token){
            headers.set("authorization", `Bearer ${token}`);
        }
        return headers;
    }
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
    
    let result = await baseQuery(args, api, extraOptions);
    if(result?.error?.status === 403){
        console.log('sending refresh token');

        //send refresh token to get new access token
        const refreshResult = await baseQuery('/auth/refresh', api, extraOptions);

        if(refreshResult?.data){
            // store new token
            api.dispatch(setCredentials({ ...refreshResult.data}));

            //retry query with new token
            result = await baseQuery(args, api, extraOptions);
        } else {
            if(refreshResult?.error?.status === 403) {
                refreshResult.error.data.message = "Your login has expired";
            }
            return refreshResult;
        }
    }
    return result;
}

export const apiSlice = createApi({
    baseQuery: baseQueryWithReauth,
    tagTypes: ['Comment', 'Game', 'Notification', 'Panel', 'User', 'CurrentUser'],
    endpoints: builder => ({})
});