import React from 'react';

class CanvasDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isMouseClicked: false,
      isMouseInBounds: false,
      lastX: 0,
      lastY: 0,
      pressure: 0,
      color: props.color,
      weight: props.weight,
      bgColor: props.bgColor,
      currentLine: [],
    };

    this.canvasRef = React.createRef();
    this.backgroundRef = React.createRef();
  }
  componentDidMount() {
    this.canvas = this.canvasRef.current;
    this.bgCanvas = this.backgroundRef.current;
    this.ctx = this.canvas.getContext('2d');
    this.ctx2 = this.bgCanvas.getContext('2d');

    window.addEventListener('pointerup', this.onPointerUp)
    this.canvas.addEventListener('pointerdown', this.onPointerDown);
    this.canvas.addEventListener('pointermove', this.onPointerMove);
    this.canvas.addEventListener('pointercancel', this.onPointerUp);
    this.canvas.addEventListener('pointerleave', this.onPointerLeave);

    this.paintBackground(this.state.bgColor);
  }

  componentWillUnmount(){
    window.removeEventListener('pointerup',this.onPointerUp);
    this.canvas.removeEventListener('pointerdown',this.onPointerDown);
    this.canvas.removeEventListener('pointermove',this.onPointerMove);
    this.canvas.removeEventListener('pointercancel',this.onPointerUp);
    this.canvas.removeEventListener('onpointerleave',this.onPointerLeave);
  }

  componentDidUpdate(prevProps){
    if (prevProps.color !== this.props.color)
      this.setState({color:this.props.color});
    if (prevProps.bgColor !== this.props.bgColor)
      this.setState({bgColor:this.props.bgColor});
    if (prevProps.weight !== this.props.weight)
      this.setState({weight:this.props.weight});
  }

  paintBackground(color){
    this.ctx2.beginPath();
    this.ctx2.fillStyle = color;
    this.ctx2.fillRect(0,0,this.bgCanvas.width,this.bgCanvas.height);
  }

  onPointerDown = (e) => {
    const { offsetX, offsetY, pressure } = e;

    this.setState({
      isMouseClicked: true,
      lastX: offsetX,
      lastY: offsetY,
      pressure: pressure,
    });
    const {color, weight} = this.state;
    this.drawLine(offsetX, offsetY, offsetX, offsetY, pressure, color, weight);
    this.setState({currentLine:[...this.state.currentLine,{lastX: offsetX, lastY: offsetY, offsetX, offsetY, pressure, color, weight}]});
  }

  onPointerMove = (e) => {
    if (!this.state.isMouseClicked) return;
    this.setState({pressure:e.pressure});
    const { offsetX, offsetY } = this.state.isMouseInBounds ? e : this.state;
    this.setState({isMouseInBounds:true});
    const { lastX, lastY, pressure, color, weight} = this.state;

    this.drawLine(lastX, lastY, offsetX, offsetY, pressure, color, weight);
    this.setState({currentLine:[...this.state.currentLine,{lastX, lastY, offsetX, offsetY, pressure, color, weight}]});
    this.setState({
      lastX: offsetX,
      lastY: offsetY,
    });
  }

  addHistory = () => {
    this.props.addToHistory(this.state.currentLine);
    this.setState({currentLine:[]});
  }

  onPointerUp = () => {
    if(this.state.isMouseClicked){
      this.setState({ isMouseClicked: false });
      this.addHistory();
    }
  }

  onPointerLeave = () => {
    this.setState({ isMouseInBounds: false });
  }

  drawLine = (fromX, fromY, toX, toY, pressure, color, weight) => {
    this.ctx.strokeStyle = color;
    this.ctx.lineWidth = 2 * pressure * (weight+1) * (weight+1) + weight - 2;
    this.ctx.lineCap = 'round';
    this.ctx.beginPath();
    this.ctx.moveTo(fromX, fromY);
    this.ctx.lineTo(toX, toY);
    this.ctx.stroke();
  }

  //Clears all components on canvas
  clearCanvas(){
    this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height);
    this.ctx.beginPath();
  }

  //convert canvas to DataURL
  saveCanvas(){
    return this.canvas.toDataURL('image/png');
  }

  //flatten drawing canvas to background and save full drawing to dataURL
  renderSaveCanvas(){
    this.ctx2.drawImage(this.canvas,0,0);
    return this.bgCanvas.toDataURL('image/png');
  }

  //each stroke is stored as an array of lines, with the document being an array of these arrays
  //iterate through all lines and add them to a blank canvas. Ignores some JS weirdness with function being passed instead of line objects
  renderCanvas = (lines) => {
    this.clearCanvas();
    for(let i=0; i<lines.length; i++){
      if(typeof lines[i] === "function") continue;
      for(let j=0; j<lines[i].length;j++){
        let line = lines[i][j];
        this.drawLine(line.lastX,line.lastY,line.offsetX,line.offsetY,line.pressure,line.color,line.weight);
      }
      
    }
  }

  render() {
    return (
      <div style={{position:'relative', marginLeft:'auto',marginRight:'auto',width:512, height:512}}>
        <canvas id="backgroundLayer" ref={this.backgroundRef} width={512} height={512} style={{position:'absolute', top:0, left:0, zIndex:0, cursor:'crosshair'}}/>
        <canvas id="drawingLayer" ref={this.canvasRef} width={512} height={512} style={{position:'absolute', top:0,left:0,zIndex:999, cursor:'crosshair'}}/>
      </div>
      
    );
  }
}

export default CanvasDrawer;