import React from 'react'
import { useParams } from 'react-router-dom'

<>
</>
const PanelView = () => {
    let { panelId } = useParams();
  return (
    <>
        <h3>PanelView</h3>
        <p>Panel ID: {panelId}</p>
    </>
    
  )
}

<>
</>
export default PanelView