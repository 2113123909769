import { createSlice } from "@reduxjs/toolkit";

const gameSlice = createSlice({
    name: 'game',
    initialState: {active: null},
    reducers: {
        setActiveGame: (state, action) => {
            const  { game }  = action.payload;
            state.active = game;
        },
        removeActiveGame: (state, action) => {
            state.active = null;
        },
    }
});

export const { setActiveGame, removeActiveGame } = gameSlice.actions;
export default gameSlice.reducer;
export const selectActiveGame = (state) => state.game.active;