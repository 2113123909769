import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUserById } from "./usersApiSlice";

import React from 'react'
import { Avatar, IconButton, TableCell, TableRow } from "@mui/material";
import { Check, Close, Person } from "@mui/icons-material";

const User = ({userId}) => {
    const user = useSelector(state => selectUserById(state, userId));
    const navigate = useNavigate();

    if(user){
        const handleView = () => navigate(`/user/${user.username}`);
        const userPremiumStatusAsString = user.premium ? <Check color="success"/> : <Close color="error"/>;
        const userActiveStatusAsString = user.active ? <Check color="success"/> : <Close color="error"/>;
        return (
            <TableRow>
                <TableCell align="left">
                    <IconButton onClick={handleView}>
                        <Avatar src=""><Person/></Avatar>
                        
                    </IconButton>
                </TableCell>
                <TableCell align="left">{user.username}</TableCell>
                <TableCell align="left">{user.email}</TableCell>
                <TableCell align="left">{user.role}</TableCell>
                <TableCell align="left">{userActiveStatusAsString}</TableCell>
                <TableCell align="left">{userPremiumStatusAsString}</TableCell>
            </TableRow>
        )
    } else return null
    
}

export default User