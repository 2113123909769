import React, { useEffect } from 'react'
import { useGetPlayableGameMutation } from '../gamesApiSlice'
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, Paper, Slider, Typography } from '@mui/material';
import { selectActiveGame } from '../gameSlice';
import { useSelector } from 'react-redux';
import CanvasDrawer from '../../../components/CanvasDrawer/CanvasDrawer';
import { Circle, Delete, RadioButtonChecked, Redo, SkipNext, Undo } from '@mui/icons-material';
import { selectPaletteById } from '../../palette/paletteApiSlice';

const DrawView = (props) => {
    const [color, setColor] = React.useState('#000000');
    const [weight, setWeight] = React.useState(3);
    const [isEraser, setIsEraser] = React.useState(false);
    const [history, setHistory] = React.useState([]);
    const [historyIndex, setHistoryIndex] = React.useState(0);
    const [historyMaxSize, setHistoryMaxSize] = React.useState(5);
    const [canDraw, setCanDraw] = React.useState(true);
    const palette = props.activePalette;

    const canvasDrawerRef = React.useRef(null);

    const clearCanvas = () => {
        canvasDrawerRef.current.clearCanvas();
        // Reset canvas, no more undo/redo
        setHistory([]);
        setHistoryIndex(0);
    };

    const submitCanvas = () => {
        const drawing = canvasDrawerRef.current.renderSaveCanvas();
        props.submitCanvas(drawing,"drawing");
    }

    const skipGame = () => {
        props.skipGame();
    }

    const renderCanvas = (lines) => {
        canvasDrawerRef.current.renderCanvas(lines);
    }

    const addToHistory = (lines) => {
        const hist = history.slice(0,historyIndex)
        setHistory([...hist, lines]);
        setHistoryIndex(hist.length+1);
    }

    const canUndo = () => {
        return historyIndex > 0;
    }

    const canRedo = () => {
        return historyIndex < history.length;
    }

    const undo = () => {
        const index = historyIndex-1;
        setHistoryIndex(index);
        renderCanvas(history.slice(0,index));
    }

    const redo = () => {
        const index = historyIndex+1;
        setHistoryIndex(index);
        renderCanvas(history.slice(0,index));
        }

    return (
        <Card sx={{ margin: '3rem' }}>
            <CardContent sx={{ textAlign: 'center' }}>
            <Typography variant='h3' fontFamily={'Acme'} sx={{mb:2}}>10:00</Typography>
                <Grid container direction={'row'} display={'flex'} spacing={4}>
                <Grid item container direction={'column'} xs={2} sx={{maxHeight:576}}>
                        {palette.values?.map((c) => {
                            return (
                                <Grid item key={c}>
                                    <IconButton onClick={() => setColor(c)}>
                                        {c === color ? <RadioButtonChecked sx={{ color: c, fontSize: '3rem' }} /> : <Circle sx={{ color: c, fontSize: '3rem' }} />}
                                    </IconButton>
                                </Grid>
                            );
                        })}
                    </Grid>
                    <Grid item xs={10}>
                    <CanvasDrawer ref={canvasDrawerRef} color={color} weight={weight} bgColor='white' addToHistory={addToHistory} />
                    </Grid>
                    
  
                </Grid>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '1rem' }}>
                    <Slider sx={{ marginRight: '1rem' }} size="medium" aria-label="Brush Size" defaultValue={3.5} valueLabelDisplay="off" step={2.5} marks min={1} max={11} onChangeCommitted={(e, val) => setWeight(val)} />
                    <ButtonGroup>
                        <IconButton onClick={undo} disabled={!canUndo()}>
                            <Undo sx={{ fontSize: '3rem' }} />
                        </IconButton>
                        <IconButton onClick={redo} disabled={!canRedo()}>
                            <Redo sx={{ fontSize: '3rem' }} />
                        </IconButton>
                        <IconButton onClick={clearCanvas}>
                            <Delete sx={{ fontSize: '3rem' }} color="error" />
                        </IconButton>
                    </ButtonGroup>
                </div>
                <Button variant="contained" color="success" sx={{m:2}} onClick={submitCanvas}>
                    <Typography variant="h3" color={'white'} fontFamily={'Acme'}>Submit</Typography>
                </Button>
                <Button variant="contained" color="error" sx={{m:2}} onClick={skipGame}>
                    <Typography variant="h3" color={'white'} fontFamily={'Acme'}>Skip</Typography> 
                    <SkipNext fontSize='large'/>
                </Button>

            </CardContent>
        </Card>
    )
}

export default DrawView