import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const notificationsAdapter = createEntityAdapter({})
const initialState = notificationsAdapter.getInitialState();

export const notificationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNotifications: builder.query({
            query: () => `/notifications`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                const loadedNotifications = responseData.map(notification => {
                    notification.id = notification._id;
                    return notification;
                });
                return notificationsAdapter.setAll(initialState, loadedNotifications);
            },
        })
    })
});

export const {
    useGetNotificationsQuery,
} = notificationsApiSlice;

// returns query result object
export const selectNotificationsResult = notificationsApiSlice.endpoints.getNotifications.select();

//creates memoized selector
const selectNotificationsData = createSelector(
    selectNotificationsResult,
    notificationsResult => notificationsResult.data
)

//getSelectors creates selectors which are renamed with aliases during destructuring
export const {
    selectAll: selectNotifications,
    // pass in a selector that returns the users slice of state
} = notificationsAdapter.getSelectors(state => selectNotificationsData(state) ?? initialState);