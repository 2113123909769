import './App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import GameView from './features/games/GameView';
import Layout from './components/Layout';
import Login from './features/auth/Login';
import Public from './components/Public';
import UsersList from './features/users/UsersList';
import GamesList from './features/games/GamesList';
import UserView from './features/users/UserView';
import PanelView from './features/panels/PanelView';
import SignUpForm from './features/users/SignUpForm';
import Home from './features/auth/Home';
import PersistLogin from './features/auth/PersistLogin';
import Prefetch from './features/auth/Prefetch';
import Profile from './features/users/Profile';
import NotFound from './components/NotFound';
import Contact from './components/Contact';
import DrawView from './features/games/PlayViews/DrawView';
import Play from './features/games/PlayViews/Play';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout/>}>
        <Route index element={<Public/>}/>
        <Route path='contact' element={<Contact/>}/>
        <Route path='404' element={<NotFound/>}/>
        <Route caseSensitive={false} path="user">
          <Route index element={<UsersList/>}/>
          <Route caseSensitive={false} path=':username'>
            <Route index element={<UserView/>}/>
            <Route path=':panelId' element={<PanelView/>}/>
          </Route>
        </Route>
        <Route caseSensitive={false} path="login" element={<Login/>} />
        <Route caseSensitive={false} path="signup" element={<SignUpForm/>}/>
        <Route caseSensitive={false} path="/games">
            <Route path=':page?' element={<GamesList/>}/>
            <Route path='id/:gameId' element={<GameView/>}/>
        </Route>

        <Route element={<PersistLogin/>}>
          <Route element={<Prefetch/>}>
            <Route caseSensitive={false} path="home" element={<Home/>}/>
            <Route caseSensitive={false} path="profile" element={<Profile/>}/>
            <Route caseSensitive={false} path='play' element={<Play/>}/>
          </Route>
        </Route>
      </Route>
      <Route path='*' element={<Navigate to='/404'/>}/>
    </Routes>
    /*
    <div>
      <GameView palette={['#FF0000','#00FF00','#0000FF','#00FFFF','#FF00FF','#FFFF00','#FEE3D4','#593123','#FFFFFF','#000000']} gamePrompt="Cat with a moustache"/>
    </div>
   */
  );
}

export default App;
