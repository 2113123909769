import React from 'react'
import { useSendLogoutMutation } from "../../features/auth/authApiSlice";
import { useSelector } from 'react-redux';
import { selectCurrentUser } from '../../features/auth/currentUserSlice';
import { Avatar, IconButton, ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import { Logout, Person, Settings } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const AvatarMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleAvatarClick = (event) => {
        setAnchorEl(event.currentTarget);
    }
    const handleAvatarClose = () => {
        setAnchorEl(null);
    }
    const logOut = () => {
        sendLogout();
        navigate('/');
    }
    const [sendLogout, {
        isLoading: isLogoutLoading,
        isSuccess: isLogoutSuccess,
        isError: isLogoutError,
        error: logoutError
    }] = useSendLogoutMutation();
    const navigate = useNavigate();

    const user = useSelector(selectCurrentUser);
    //if(!user) navigate('/login')
    return (
        <>
            <IconButton onClick={handleAvatarClick}>
                <Avatar src={`avatars/${user?.profile?.avatar}.png`} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={Boolean(anchorEl)}
                onClick={handleAvatarClose}
                onClose={handleAvatarClose}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Typography variant="h6" fontFamily='Acme' align="center">{user?.username}</Typography>
                <hr style={{ width: '80%', opacity: '25%' }} />
                <MenuItem onClick={() => { navigate('/profile') }}>
                    <ListItemIcon>
                        <Person fontSize="small" />
                    </ListItemIcon>
                    Profile
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <Settings fontSize="small" />
                    </ListItemIcon>
                    Settings
                </MenuItem>
                <MenuItem onClick={logOut}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}

export default AvatarMenu