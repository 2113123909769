import { React, useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectCurrentToken, setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'

import { Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Modal, Paper, TextField, Typography } from '@mui/material'

const Login = () => {
  const userRef = useRef();
  const errRef = useRef();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');
  const [forgotModalOpen, setForgotModalOpen] = useState(false);
  const [persist, setPersist] = usePersist();
  
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  
  const [login, { isLoading }] = useLoginMutation();

  useEffect(() => {
    userRef.current.focus()
  }, []);
  useEffect(() => {
    setErrMsg();
  }, [username, password]);
  useEffect(()=>{
    if(token) navigate('/home');
  },[token])
  const handleToggle = () => setPersist(prev => !prev);
  const handleSubmit = async (e) => {
    e.preventDefault();
    try{
      const { accessToken } = await login({ username, password}).unwrap();
      dispatch(setCredentials({ accessToken }));
      setUsername('');
      setPassword('');
      navigate('/home');
    } catch(err) {
      if (!err.status) setErrMsg('No Server Response');
      else setErrMsg(err.data?.message);
      errRef.current.focus();
    }
  }


 

  const content = (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{ my: 4 }}>
      <Typography variant='h3'>Log In</Typography>
      <Typography color="error" sx={{my:1}} ref={errRef} aria-live="assertive">{errMsg}</Typography>
      <FormGroup>
      <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center">
          <TextField
            label='Username'
            value={username}
            onChange={e => setUsername(e.target.value)}
            inputProps={{ maxLength: 16 }}
            sx={{ my: 1 }}
            variant="standard"
            ref={userRef}
          />
          <TextField
            label='Password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            inputProps={{ maxLength: 32 }}
            type='password'
            sx={{ my: 1 }}
            variant="standard"
            autoComplete='on'
          />
          
          <ButtonGroup sx={{my:1}}>
            <FormControlLabel control={<Button onClick={() => setForgotModalOpen(true) }>Forgot Password</Button>} />
            <FormControlLabel control={<Button variant='contained' onClick={handleSubmit}>Submit</Button>} />
          </ButtonGroup>
          <FormControlLabel control={<Checkbox checked={persist} onChange={handleToggle}/>} label='Stay signed in'/>
          </Grid>
        </FormGroup>
        <Modal open={forgotModalOpen} onClose={() => setForgotModalOpen(false)}>
            <Grid container spacing={0} direction="column" alignItems="center" justifyContent="center" sx={{m:4}}>
              <Typography variant='h5'>Too bad. This hasn't been implemented yet</Typography>
            </Grid>
        </Modal>
    </Grid>
    </form>
    
  )
  return content
}

export default Login