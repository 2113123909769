import { useGetGamesQuery } from './gamesApiSlice'

import React from 'react'
import GameSample from './GameSample';
import { Card, Container, Table, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';



const GamesList = () => {
    let { page } = useParams();
    const { 
        data: games,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetGamesQuery(page);

    let content;

    if (isLoading) content = <p>Loading...</p>
    if(isError){
        content = <Typography sx={{mt:2}}variant="h3" fontFamily={'Acme'} align="center">{error?.data?.message}</Typography>
    }

    if(isSuccess){
        const { ids } = games;

        const tableContent = ids?.length
        ? ids.map(gameId => <GameSample gameId={gameId} key={gameId} />)
        : null;

        content = (
            <Container>
                <Table sx={{ minWidth: 650 }} aria-label="games">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Mode</TableCell>
                            <TableCell align="center">First Panel</TableCell>
                            <TableCell align="center">Last Panel</TableCell>
                        </TableRow>
                        {tableContent}
                    </TableHead>
                </Table>
            </Container>
        )
    }
    return content;
}

export default GamesList