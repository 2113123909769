import { Outlet } from "react-router-dom";

import React from 'react'
import Footer from "./Banner/Footer";
import Header from "./Banner/Header";

const Layout = () => {
  return (
    <>
        <Header/>
        <Outlet/>
        <Footer/>
    </>
  );
};

export default Layout;