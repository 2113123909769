import React, { useEffect } from 'react'
import { useGetPlayableGameMutation } from '../gamesApiSlice'
import { Button, ButtonGroup, Card, CardContent, Grid, IconButton, Paper, Slider, TextField, Typography } from '@mui/material';
import { selectActiveGame } from '../gameSlice';
import { useSelector } from 'react-redux';
import { Circle, Delete, RadioButtonChecked, Redo, SkipNext, Undo } from '@mui/icons-material';
import { selectPaletteById } from '../../palette/paletteApiSlice';

const CaptionView = (props) => {
    const [captionContent, setCaptionContent] = React.useState('');
    const onCaptionChanged = (e) => { setCaptionContent(e.target.value.substr(0, 64)); }
    const submitCanvas = () => {
        props.submitCanvas(captionContent, "caption");
    }
    const skipGame = () => {
        props.skipGame();
    }

    return (
        <Card sx={{ margin: '3rem' }}>
            <CardContent sx={{ textAlign: 'center' }}>
                <Grid container direction={'column'}>

                
                <Typography variant='h3' fontFamily={'Acme'} fontWeight={700} sx={{ mb: 2 }}>10:00</Typography>
                <Typography variant='h4' fontFamily={'Acme'} sx={{ mb: 2 }}>Enter your caption below:</Typography>
                <TextField value={captionContent} onChange={onCaptionChanged} />
                <Grid item container direction={'row'}>
                <Button variant="contained" color="success" sx={{ m: 2 }} onClick={submitCanvas}>
                    <Typography variant="h3" color={'white'} fontFamily={'Acme'}>Submit</Typography>
                </Button>
                <Button variant="contained" color="error" sx={{ m: 2 }} onClick={skipGame}>
                    <Typography variant="h3" color={'white'} fontFamily={'Acme'}>Skip</Typography>
                    <SkipNext fontSize='large' />
                </Button>
                </Grid>
                </Grid>

            </CardContent>
        </Card>
    )
}

export default CaptionView