import React from 'react'
import { useParams } from 'react-router-dom'


const UserView = () => {
  let { username } = useParams();
    return (
    <h3>User: {username}</h3>
  )
}


export default UserView