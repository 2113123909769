import React from 'react'
import { useSelector } from 'react-redux'
import { selectCurrentToken } from '../../features/auth/authSlice'
import { useGetNotificationsQuery } from '../../features/notifications/notificationsApiSlice';
import BannerLoggedOut from './BannerLoggedOut';
import BannerLoggedIn from './BannerLoggedIn';


const Header = () => {

    const token = useSelector(selectCurrentToken);
    const content = token ? <BannerLoggedIn/> : <BannerLoggedOut/>;

    return content;

}

export default Header