import { useNavigate } from "react-router-dom";
import React from 'react'
import { AppBar,  Box, Button, ButtonGroup, Toolbar, Typography } from "@mui/material";
import logo from '../../images/logo192.png'


const BannerLoggedOut = () => {

    const navigate = useNavigate();
    //const { pathname } = useLocation();
    const handleSignupOpen = () => navigate('/signup');

    const onGamesClicked = () => navigate('/games');
    const onGoRootClicked = () => navigate('/');
    //const onUserHovered = () => {};
    //TODO: Make User profile clicked go to user
    //const onUserClicked = () => 
    const content = (
        <header>
            <AppBar position="static">
                    <Toolbar>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 5 }}>
                        <Button onClick={onGoRootClicked} title="home" disableRipple disableElevation sx={{"&:hover": {backgroundColor: 'transparent' }}}>
                            <img src={logo} style={{width: 'auto', height: 64}} aria-label="ArtRush"/>
                        </Button>
                        <Button sx={{ mx: 2 }}>
                            <Typography variant="h6" component="div" onClick={() => {navigate('/games')}} fontFamily={'Acme'}>
                                Finished Games
                            </Typography>
                        </Button>
                        </Box>
                        <Box sx={{ flexGrow: 1 }}/>
                        <ButtonGroup sx={{mr: 16 }}>
                            <Button variant="contained" color="info" >
                                <Typography variant="h6" color="White" fontWeight="700" onClick={handleSignupOpen} fontFamily={'Acme'}>
                                    Sign Up
                                </Typography>
                            </Button>
                            <Button color="secondary">
                                <Typography variant="h6" color="White" fontWeight="700" onClick={() => navigate('/login')} fontFamily={'Acme'}>
                                    Log In
                                </Typography>
                            </Button>
                        </ButtonGroup>
                    </Toolbar>
            </AppBar>
        </header>
    );
    return content;
}

export default BannerLoggedOut