import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";
import { setCurrUser } from "../auth/currentUserSlice";

const usersAdapter = createEntityAdapter({});
const initialState = usersAdapter.getInitialState();

export const usersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAllUsers: builder.query({
            query: () => '/users',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                const loadedUsers = responseData.map(user => {
                    user.id = user._id;
                    return user;
                });
                return usersAdapter.setAll(initialState, loadedUsers);
            },
            providesTags: (result, error, arg) => {
                if(result?.ids){
                    return [
                        { type: 'User', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'User', id}))
                    ];
                } else return [{ type: 'User', id: 'LIST'}];
            }
        }),
        //Creates a new user
        createNewUser: builder.mutation({
            query: initialUserData => ({
                url: '/users',
                method: 'POST',
                body: { ...initialUserData }
            }),
            invalidatesTags: [
                {type: 'User', id: "LIST" }
            ],
            providesTags: (result, error, arg) => [
                {type: 'User', id: result.id}
            ]
        }),
        //Get individual user by username
        getUser: builder.query({
            query: (username) => `/users/${username}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            transformResponse: responseData => {
                const loadedUser = {...responseData, id: responseData._id};
                return usersAdapter.upsertOne(initialState, loadedUser);
            },
            providesTags: (result, error, arg) => {
                return [{ type: 'User', id: result.id}];
            }
        }),
        updateUser: builder.mutation({
            query: (username, initialUserData) => ({
                url: `/users/${username}`,
                method: 'PATCH',
                body: {
                    ...initialUserData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'User', id: arg.id }
            ]
        }),

    })
});

export const {
    useGetAllUsersQuery,
    useGetUserQuery,
    useCreateNewUserMutation,
    useUpdateUserMutation,
} = usersApiSlice;

// returns query result object
export const selectUsersResult = usersApiSlice.endpoints.getAllUsers.select();

//creates memoized selector
const selectUsersData = createSelector(
    selectUsersResult,
    usersResult => usersResult.data
)

//getSelectors creates selectors which are renamed with aliases during destructuring
export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
    // pass in a selector that returns the users slice of state
} = usersAdapter.getSelectors(state => selectUsersData(state) ?? initialState);