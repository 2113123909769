import { Instagram, Reddit, Twitter } from '@mui/icons-material';
import { Toolbar, AppBar, Grid, Typography, Button, Card } from '@mui/material'
import React from 'react'

const Footer = () => {
    const year = new Date().getFullYear();
  return (
    <Card sx={{position:"fixed", width:'100%', bottom:0, top:'auto', p:2, m:0}} >
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
                <address style={{fontSize:12}}>
                    {`© ${year} ArtRush. All rights reserved. Created by oleusi`}<br/>
                    View our <a style={{color:"inherit"}} href='/PrivacyPolicy.txt'>Privacy Policy</a> and <a style={{color:"inherit"}} href='/TermsAndConditions.txt'>Terms and Conditions</a><br/>
                     For all questions and notices please fill out our <a style={{color:"inherit"}} href="/contact">contact form</a><br/>
                </address>
                <Typography variant='h5' sx={{opacity:.25}}>New updates are always on the way!</Typography>
                <Grid justifyContent="center" alignItems="center">
                    <Button sx={{mx:2}}>
                        <Instagram />
                    </Button>
                    <Button sx={{mx:2}}>
                    <Reddit sx={{mx:2}}/>
                    </Button>
                </Grid>
            </Grid>
    </Card>
  )
}

export default Footer