import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectGameById } from "./gamesApiSlice";

import React from 'react'
import { Box, Card, CardMedia, TableCell, TableRow, Tooltip, Typography } from "@mui/material";

const GameSample = ({ gameId }) => {

    const game = useSelector(state => selectGameById(state, gameId));

    const navigate = useNavigate();

    if (game) {
        const firstPanel = game.firstPanel.panelType === "Caption"
            ? <Typography fontFamily={'Acme'} variant="h4" sx={{ wordBreak: "break-word", m: 1 }}>{game.firstPanel.content}</Typography>
            : <CardMedia component='img' height={256} image={`http://localhost:9000/images/${game.firstPanel.content}.png`} alt="first drawing"/>
        const lastPanel = game.lastPanel.panelType === "Caption"
            ? <Typography fontFamily={'Acme'} variant="h4" sx={{ wordBreak: "break-word", m: 1 }}>{game.lastPanel.content}</Typography>
            : <CardMedia component='img' height={256} image={`http://localhost:9000/images/${game.lastPanel.content}.png`} alt="last drawing"/>

        return (
            <TableRow>
                <TableCell align="center">
                    <Box display='flex' margin='auto' justifyContent='center' alignContent='center'>
                        <Typography fontFamily={'Acme'} variant="h4">{game.gameMode}</Typography>
                    </Box>
                </TableCell>
                <TableCell align="center">
                    <Box display='flex' margin='auto' justifyContent='center' alignContent='center'>
                        <Tooltip title={game.firstPanel.user} arrow>
                            <Card sx={{ width: 256, height: 256, objectFit:'fill'}} raised onClick={() => {navigate(`/games/id/${gameId}`)}}>
                                {firstPanel}
                            </Card>
                        </Tooltip>
                    </Box>

                </TableCell>
                <TableCell align="center">
                    <Box display='flex' margin='auto' justifyContent='center' alignContent='center'>
                        <Tooltip title={game.lastPanel.user} arrow>
                            <Card sx={{ width: 256, height: 256, objectFit:'fill'}} raised onClick={() => {navigate(`/games/id/${gameId}`)}} >
                                {lastPanel}
                            </Card>
                        </Tooltip>
                    </Box>
                </TableCell>
            </TableRow>
        )
    } else return null
}

export default GameSample