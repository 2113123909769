import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: 'user',
    initialState: {current: null},
    reducers: {
        setCurrentUser: (state, action) => {
            const { user } = action.payload;
            state.current = user;
        },
        removeCurrentUser: (state, action) => {
            state.current = null;
        },
    }
});

export const { setCurrentUser, removeCurrentUser } = userSlice.actions;
export default userSlice.reducer;
export const selectCurrentUser = (state) => state.user.current;