import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice";

const gamesAdapter = createEntityAdapter({})
const initialState = gamesAdapter.getInitialState();

export const gamesApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getGames: builder.query({
            query: (page) => `/games/new/${page ? page : ""}`,
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError;
            },
            keepUnusedDataFor: 5,
            transformResponse: responseData => {
                const loadedGames = responseData.map(game => {
                    game.id = game._id;
                    return game;
                });
                return gamesAdapter.setAll(initialState, loadedGames);
            },
            providesTags: (result, error, arg) => {
                if(result?.ids){
                    return [
                        { type: 'Game', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Game', id}))
                    ];
                } else return [{ type: 'Game', id: 'LIST'}];
            }
        }),
        getPlayableGame: builder.mutation({
            query: (id) => ({
                url: `/games/play/${id ? id : ""}`,
                method: 'PUT',
            })
        }),
        submitGame: builder.mutation({
            query: gameBody => ({
                url: `/games/submit/${gameBody.id}`,
                method: 'PUT',
                body: {...gameBody}
            })
        }),
        newGame: builder.mutation({
            query: gameBody => ({
                url: '/games',
                method: 'POST',
                body: {...gameBody}
            })
        }),
        skipGame: builder.mutation({
            query: (gameBody) => ({
                url: `/games/submit/${gameBody.id}`,
                method: 'PATCH'
            }) 
        })
    })
});

export const {
    useGetGamesQuery,
    useGetPlayableGameMutation,
    useSubmitGameMutation,
    useNewGameMutation,
    useSkipGameMutation
} = gamesApiSlice;

// returns query result object
export const selectGamesResult = gamesApiSlice.endpoints.getGames.select();

//creates memoized selector
const selectGamesData = createSelector(
    selectGamesResult,
    gamesResult => gamesResult.data
)

//getSelectors creates selectors which are renamed with aliases during destructuring
export const {
    selectAll: selectAllGames,
    selectById: selectGameById,
    selectIds: selectGameIds
    // pass in a selector that returns the games slice of state
} = gamesAdapter.getSelectors(state => selectGamesData(state) ?? initialState);