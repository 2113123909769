import { Circle, Square } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { selectPaletteById } from './paletteApiSlice'

const Palette = ({paletteId}) => {
    const palette = useSelector(state => selectPaletteById(state, paletteId));
    const {values, name} = palette;

    const colors = values?.length ? values.map(color => <Circle key={color} sx={{color:color, outlineColor:'black', outlineWidth:'thin'}}/>) : null;
    const content = 
    <Grid container direction={'column'}>
        <Grid item>
            <Typography variant='h6' fontFamily={'Acme'}>{name}</Typography>
        </Grid>
        <Grid item>
            <Grid container direction={'row'}>
                {colors}
            </Grid>
        </Grid>
    </Grid>
  return content;
}

export default Palette